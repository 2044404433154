import API from "@/main";
import store from "./index.js";

const state = {
  paymentProviders: null,
  partnerPaymentProviders: null,
  marketplacePaymentProviders: null,
};

const getters = {
  getMarketplacePaymentProvidersByType: (state) => (type) => {
    if (
      !state.marketplacePaymentProviders ||
      state.marketplacePaymentProviders[type] === undefined
    ) {
      return [];
    }

    return state.marketplacePaymentProviders[type];
  },
};

const actions = {
  loadPaymentProviders({ commit }) {
    return new Promise((resolve, reject) => {
      API.get(`system/v1/marketplace/payment-providers/provider-list`)
        .then((response) => {
          commit("SET_PAYMENT_PROVIDERS", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadPartnerPaymentProviders({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(
        `system/v1/marketplace/${payload.marketplaceId}/partner/payment_data`
      )
        .then((response) => {
          commit("SET_PARTNER_PAYMENT_PROVIDERS", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadMarketplacePaymentProviders({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(
        `system/v1/marketplace/${payload.marketplaceId}/payment-providers`
      )
        .then((response) => {
          commit("SET_MARKETPLACE_PAYMENT_PROVIDERS", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  storePaymentProvider(_, payload) {
    return new Promise((resolve, reject) => {
      API.post(
        `system/v1/marketplace/${payload.marketplaceId}/payment-providers`,
        payload.data
      )
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Провайдер привязан",
            type: "success",
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            store.dispatch("createNotification", {
              title: "Провайдер не привязан",
              description: "Данные введены неверно",
              type: "warning",
            });
          } else {
            store.dispatch("createNotification", {
              title: "Провайдер не привязан",
              description: "Что-то пошло не так",
              type: "warning",
            });
          }

          reject(error);
        });
    });
  },

  destroyPaymentProvider(_, payload) {
    return new Promise((resolve, reject) => {
      API.delete(
        `system/v1/marketplace/${payload.marketplaceId}/payment-providers/${payload.providerId}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_PAYMENT_PROVIDERS(state, payload) {
    state.paymentProviders = payload;
  },

  SET_PARTNER_PAYMENT_PROVIDERS(state, payload) {
    state.partnerPaymentProviders = payload;
  },

  SET_MARKETPLACE_PAYMENT_PROVIDERS(state, payload) {
    state.marketplacePaymentProviders = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
