import API from "@/main";
import store from "./index";

const state = {
  categories: [],
  statusCategories: "",
};

const getters = {};

const actions = {
  loadCategories({ commit }, payload) {
    commit("SET_STATUS_CATEGORIES", "LOADING");

    return new Promise((resolve, reject) => {
      API.get("system/v1/categories", {
        params: {
          search: payload.search,
          page: payload.page,
        },
      })
        .then((response) => {
          commit("SET_CATEGORIES", response.data.data);
          commit("SET_STATUS_CATEGORIES", "SUCCESS");

          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_STATUS_CATEGORIES", "ERROR");

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  storeCategory(_, payload) {
    return new Promise((resolve, reject) => {
      API.post("system/v1/categories", {
        name: payload.name,
      })
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Категория добавлена",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          let title = "Что-то пошло не так";
          let description = "Перезагрузите страницу или попробуйте позже";

          if (error.response.status === 422) {
            title = error.response.data.message;
            description = "";
          }

          store.dispatch("createNotification", {
            title: title,
            description: description,
            type: "warning",
          });
        });
    });
  },

  updateCategory(_, payload) {
    return new Promise((resolve, reject) => {
      API.put(`system/v1/categories/${payload.categoryId}`, {
        name: payload.name,
      })
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Данные изменены",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          let title = "Что-то пошло не так";
          let description = "Перезагрузите страницу или попробуйте позже";

          if (error.response.status === 422) {
            title = error.response.data.message;
            description = "";
          }

          store.dispatch("createNotification", {
            title: title,
            description: description,
            type: "warning",
          });
        });
    });
  },

  deleteCategory(_, payload) {
    return new Promise((resolve, reject) => {
      API.delete(`system/v1/categories/${payload.categoryId}`)
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Категория удалена",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },
};

const mutations = {
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },

  SET_STATUS_CATEGORIES(state, payload) {
    state.statusCategories = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
