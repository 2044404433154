import { createStore } from "vuex";
import auth from "./auth.store.js";
import categories from "./categories.store";
import roles from "./roles.store";
import staff from "./staff.store";
import marketplaces from "./marketplaces.store";
import partners from "./partners.store";
import paymentProviders from "./payment-providers.store";
import logs from "./logs.store";
import selfCollections from "./self-collection.store";
import moderation from "./moderation.store";
import transactions from "./transactions.store";
import refunds from "./refunds.store";

export default createStore({
  state: {
    notification: null,
    innerWidth: window.innerWidth,

    isShowDownloadRegistryModal: false,
  },

  getters: {
    checkPermissions: () => (permissions) => {
      return permissions.some((permission) => {
        return auth.state.permissions.includes(permission);
      });
    },
  },

  mutations: {
    SET_NOTIFICATION(state, data) {
      state.notification = data;
    },

    SET_INNER_WIDTH(state, data) {
      state.innerWidth = data;
    },

    SET_MODAL_STATE(state, { modalName, modalState }) {
      state[modalName] = modalState;
    },
  },

  actions: {
    createNotification({ commit, state }, data) {
      if (state.notification) {
        commit("SET_NOTIFICATION", null);

        setTimeout(() => {
          commit("SET_NOTIFICATION", data);
        }, 50);
      } else {
        commit("SET_NOTIFICATION", data);
      }
    },
  },

  modules: {
    auth,
    categories,
    roles,
    staff,
    marketplaces,
    paymentProviders,
    partners,
    logs,
    selfCollections,
    moderation,
    transactions,
    refunds,
  },
});
