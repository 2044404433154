import API from "@/main";

const state = {};

const getters = {};

const actions = {
  loadTransactions(_, payload) {
    return new Promise((resolve, reject) => {
      API.get("system/v1/transactions", {
        params: payload,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  exportTransactions(_, payload) {
    return new Promise((resolve, reject) => {
      API.get("system/v1/transactions/export", {
        responseType: "blob",
        params: payload,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
