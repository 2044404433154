import API from "@/main.js";
import store from "./index.js";

const state = {
  paymentProviders: null,
  partnerLimits: null,

  taxSystems: null,
};

const getters = {};

const actions = {
  loadPaymentProviders({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(
        `system/v1/marketplace/${payload.marketplaceId}/partner/${payload.partnerId}/payment-data`
      )
        .then((response) => {
          commit("SET_PAYMENT_PROVIDERS", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  storePaymentProvider(_, payload) {
    return new Promise((resolve, reject) => {
      API.post(
        `system/v1/marketplace/${payload.marketplaceId}/partner/${payload.partnerId}/payment-data`,
        {
          payment_provider_id: payload.payment_provider_id,
          data: payload.data,
        }
      )
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Провайдер привязан",
            type: "success",
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            store.dispatch("createNotification", {
              title: "Провайдер не привязан",
              description: "Данные введены неверно",
              type: "warning",
            });
          } else {
            store.dispatch("createNotification", {
              title: "Провайдер не привязан",
              description: "Что-то пошло не так",
              type: "warning",
            });
          }

          reject(error);
        });
    });
  },

  updatePaymentProvider(_, payload) {
    return new Promise((resolve, reject) => {
      API.put(
        `system/v1/marketplace/${payload.marketplaceId}/partner/${payload.partnerId}/payment-data/${payload.providerId}`,
        {
          data: payload.data,
        }
      )
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Данные изменены",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  destroyPaymentProvider(_, payload) {
    return new Promise((resolve, reject) => {
      API.delete(
        `system/v1/marketplace/${payload.marketplaceId}/partner/${payload.partnerId}/payment-data/${payload.providerId}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  activatePaymentProvider(_, payload) {
    return new Promise((resolve, reject) => {
      API.put(
        `system/v1/marketplace/${payload.marketplaceId}/partner/${payload.partnerId}/cashier/toggle`,
        payload.data
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadPartnerLimits({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(
        `system/v1/marketplace/${payload.marketplaceId}/partner/${payload.partnerId}/limit`
      )
        .then((response) => {
          commit("SET_PARTNER_LIMITS", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  storePartnerLimits(_, payload) {
    return new Promise((resolve, reject) => {
      API.post(
        `system/v1/marketplace/${payload.marketplaceId}/partner/${payload.partnerId}/limit`,
        payload.params
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deletePartnerLimits(_, payload) {
    return new Promise((resolve, reject) => {
      API.delete(
        `system/v1/marketplace/${payload.marketplaceId}/partner/${payload.partnerId}/limit`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  storePartnerFile(_, payload) {
    return new Promise((resolve, reject) => {
      API.post(payload.url, payload.body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deletePartnerFile(_, payload) {
    return new Promise((resolve, reject) => {
      API.delete(
        `system/v1/marketplace/${payload.id}/partner/${payload.partnerId}/upload/${payload.mediaType}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadTaxSystems({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`system/v1/marketplace/${data.marketplaceId}/partner/tax-systems`)
        .then((response) => {
          commit("SET_TAX_SYSTEMS", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_PAYMENT_PROVIDERS(state, payload) {
    state.paymentProviders = payload;
  },

  SET_PARTNER_LIMITS(state, payload) {
    state.partnerLimits = payload;
  },

  SET_TAX_SYSTEMS(state, payload) {
    state.taxSystems = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
