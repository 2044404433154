import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import * as Sentry from "@sentry/vue";
import "./scss/main.scss";
import Maska from "maska";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import "vue-slider-component/theme/antd.css";
import vClickOutside from "click-outside-vue3";

// api
const baseURL = process.env.VUE_APP_BACKEND;

const API = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: {
    "accept-language": "ru",
  },
});

API.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      store
        .dispatch("auth/logout")
        .then(() => {
          window.location.href = "/login";
        })
        .catch(() => {
          window.location.href = "/login";
        });
    }

    return Promise.reject(error);
  }
);

export default API;

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://eff14fb00eb14c1bb5656f4e40565e4a@sentry.iilab.ru/33",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  beforeSend: (event) => {
    if (window.location.hostname !== "localhost") {
      return event;
    }
  },
});

app.use(store).use(router).use(vClickOutside).use(Maska).mount("#app");
