import API from "@/main";
import formatGetParams from "@/package/helpers/format-get-params.helpers";

const state = {};

const getters = {};

const actions = {
  loadRefundsTransactions(_, payload) {
    const params = formatGetParams(payload);

    return new Promise((resolve, reject) => {
      API.get(`system/v1/refunds?${params}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateRefundTransaction(_, payload) {
    return new Promise((resolve, reject) => {
      API.put(`system/v1/refunds/${payload.transactionId}`, payload.data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
