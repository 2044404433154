import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import MainLayout from "@/components/layouts/MainLayout";
import RouteLayout from "@/components/layouts/RouteLayout";

const routes = [
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "marketplaces",
        component: RouteLayout,
        children: [
          {
            path: "",
            name: "Marketplaces",
            component: () => import("@/views/marketplaces/MarketplacesView"),
            meta: {
              title: "Маркетплейсы",
            },
          },
          {
            path: ":id",
            name: "Marketplace",
            component: () => import("@/views/marketplaces/MarketplaceView"),
          },
          {
            path: "create",
            name: "MarketplacesCreate",
            component: () => import("@/views/marketplaces/MarketplacesCreate"),
          },
          {
            path: ":id/create",
            name: "MarketplacesPartnerCreate",
            component: () => import("@/views/partners/PartnersCreate"),
          },
          {
            path: ":id/edit",
            name: "MarketplacesEdit",
            component: () => import("@/views/marketplaces/MarketplacesEdit"),
          },
          {
            path: ":id/partners/:partnerId",
            name: "Partner",
            component: () => import("@/views/partners/PartnerView"),
          },
          {
            path: ":id/partners/:partnerId/edit",
            name: "PartnersEdit",
            component: () => import("@/views/partners/PartnersEdit"),
          },
        ],
      },
      {
        path: "roles",
        component: RouteLayout,
        children: [
          {
            path: "",
            name: "Roles",
            component: () => import("@/views/roles/RolesView"),
            meta: {
              title: "Роли",
            },
          },
          {
            path: "create",
            name: "RolesCreate",
            component: () => import("@/views/roles/RolesCreate"),
          },
          {
            path: ":id/edit",
            name: "RolesEdit",
            component: () => import("@/views/roles/RolesEdit"),
          },
        ],
      },
      {
        path: "staff",
        component: () => import("@/components/layouts/RouteLayout"),
        children: [
          {
            path: "",
            name: "Staff",
            component: () => import("@/views/staff/StaffView"),
            meta: {
              title: "Сотрудники",
            },
          },
          {
            path: "create",
            name: "StaffCreate",
            component: () => import("@/views/staff/StaffCreate"),
          },
          {
            path: ":id/edit",
            name: "StaffEdit",
            component: () => import("@/views/staff/StaffEdit"),
          },
        ],
      },
      {
        path: "product-categories",
        component: RouteLayout,
        children: [
          {
            path: "",
            name: "ProductCategories",
            component: () =>
              import("@/views/product-categories/ProductCategories"),
            meta: {
              title: "Категории товаров",
            },
          },
        ],
      },
      {
        path: "/logs",
        name: "Logs",
        component: () => import("@/views/logs/LogsView"),
        meta: {
          title: "Лента активности",
        },
      },
      {
        path: "/self-collection",
        component: () => import("@/views/self-collection/SelfCollection"),
        meta: {
          title: "Самоинкассация",
        },
        children: [
          {
            path: "",
            name: "SelfCollectionPartners",
            component: () => import("@/views/self-collection/PartnersView"),
          },
          {
            path: "withdrawals-history",
            name: "SelfCollectionWriteOffs",
            component: () => import("@/views/self-collection/WriteOffs"),
          },
        ],
      },
      {
        path: "moderation",
        component: RouteLayout,
        children: [
          {
            path: "",
            name: "Moderation",
            component: () => import("@/views/moderation/ModerationView.vue"),
            meta: {
              title: "Модерация",
            },
          },
          {
            path: ":id",
            name: "ModerationPartnerView",
            component: () =>
              import("@/views/moderation/ModerationPartnerView.vue"),
          },
          {
            path: ":id/edit",
            name: "ModerationPartnerEdit",
            component: () =>
              import("@/views/moderation/ModerationPartnerEdit.vue"),
          },
        ],
      },
      {
        path: "/transactions-sbp",
        component: () => import("@/views/transactions-sbp/TransactionsSBP.vue"),
        meta: {
          title: "Транзакции СБП",
        },
        children: [
          {
            path: "",
            name: "TransactionsSBPSuccessful",
            component: () =>
              import("@/views/transactions-sbp/TransactionsSuccessful.vue"),
          },
          {
            path: "cancelled",
            name: "TransactionsSBPCancelled",
            component: () =>
              import("@/views/transactions-sbp/TransactionsCancelled.vue"),
          },
        ],
      },
      {
        path: "/refunds",
        component: RouteLayout,
        meta: {
          title: "Заявки на возврат",
        },
        children: [
          {
            path: "",
            name: "TransactionsRefunds",
            component: () => import("@/views/refunds/TransactionsRefunds.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    component: () => import("../components/layouts/AuthLayout"),
    children: [
      {
        path: "",
        name: "Authorization",
        component: () => import("../components/auth/AuthForm"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let user = store.state.auth.user;

  if (user === null) {
    store.dispatch("auth/auth").then(() => {
      store
        .dispatch("auth/checkUser")
        .then(() => {
          if (to.path === "/login") {
            next("/");
          } else {
            next();
          }
        })
        .catch(() => {
          if (to.path !== "/login") {
            next("/login");
          } else {
            next();
          }
        });
    });
  } else {
    next();
  }
});

export default router;
