import API from "@/main";
import formatGetParams from "@/package/helpers/format-get-params.helpers";

const state = {
  partnersDebts: [],
  selfCollections: [],
};

const getters = {};

const actions = {
  loadPartnersDebts({ commit }, payload) {
    const params = formatGetParams(payload);

    return new Promise((resolve, reject) => {
      API.get(`system/v1/self-collection/partners?${params}`)
        .then((response) => {
          commit("SET_PARTNERS_DEBTS", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadSelfCollections({ commit }, payload) {
    const params = formatGetParams(payload);

    return new Promise((resolve, reject) => {
      API.get(`system/v1/self-collection?${params}`)
        .then((response) => {
          commit("SET_SELF_COLLECTIONS", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  storeSelfCollection(_, payload) {
    return new Promise((resolve, reject) => {
      API.post(`system/v1/self-collection/${payload.partnerId}`, {
        amount: payload.amount,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_PARTNERS_DEBTS(state, payload) {
    state.partnersDebts = payload;
  },

  SET_SELF_COLLECTIONS(state, payload) {
    state.selfCollections = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
