<script>
export default {
  emits: ["click"],

  props: {
    color: {
      type: String,
      required: true,
    },

    text: {
      type: String,
      required: true,
    },

    iconName: {
      type: String,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: "button",
    },

    loading: {
      type: Boolean,
      default: false,
    },

    padding: {
      type: String,
      default: "0",
    },

    size: {
      type: String,
      default: "large",
    },
  },

  computed: {
    formattedStyle() {
      if (this.padding !== "0") {
        return {
          padding: this.padding,
          width: "auto",
          height: "auto",
        };
      } else {
        return {};
      }
    },
  },

  // TODO: сделать slot для иконки слева
};
</script>

<template>
  <button
    class="main-button"
    :class="[`main-button_${color}`, `main-button_${size}`]"
    :style="formattedStyle"
    :type="type"
    :disabled="disabled"
    @click.stop="$emit('click')"
  >
    <span
      v-if="iconName"
      class="main-button__icon"
      :class="`icon-${iconName}`"
    ></span>

    {{ text }}

    <slot v-if="!loading" name="icon-right"></slot>

    <span
      v-show="loading"
      class="main-button__icon-loading icon-loading"
    ></span>
  </button>
</template>

<style scoped lang="scss">
.main-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  height: 46px;

  &_large {
    @include body-1;
  }

  &_medium {
    @include text-2;
  }

  &__icon-loading {
    display: block;
    width: 20px;
    height: 20px;
    background-color: $dark-primary;
    margin-left: 8px;
  }

  &__icon {
    display: block;
    width: 20px;
    height: 20px;
    background-color: $dark-primary;
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-image: none;
    box-shadow: none !important;
  }

  &:disabled:after,
  &:disabled:before {
    content: none;
  }

  &:disabled:hover {
    filter: none;
  }

  &:disabled:active {
    background-image: none;
  }

  &_blue {
    color: $blue;
    background-color: transparent;
    height: auto;
    width: auto;
  }

  &_error {
    background-color: transparent !important;
    border: 1px solid $red !important;
    background-image: none !important;
    color: $red !important;

    &:after,
    &:before {
      content: none !important;
    }

    &:hover {
      filter: none !important;
    }
  }

  &_dark {
    background-color: $dark-primary;
    color: $light-primary;
    transition: all 0.15s linear;

    &:hover {
      box-shadow: 0 0 20px rgba(33, 33, 33, 0.7);
    }

    &:active {
      background-color: $dark-sixth;
    }
  }

  &_transparent-with-icon {
    background-color: transparent;
    color: $dark-primary;
    padding: 12px 16px !important;
    border-radius: 0 !important;
    justify-content: flex-start;

    &:hover {
      background-color: $light-second;
    }
  }

  &_transparent-green {
    background-color: transparent;
    color: $green;
    padding: 8px 16px !important;
    border-radius: 0 !important;
    justify-content: flex-start;

    &:hover {
      background-color: $light-second;
    }

    > span {
      width: 20px;
      height: 20px;
      background-color: $green;
    }
  }

  &_transparent-error {
    background-color: transparent;
    color: $red;
    padding: 8px 16px !important;
    border-radius: 0 !important;
    justify-content: flex-start;

    &:hover {
      background-color: $light-second;
    }

    > span {
      width: 20px;
      height: 20px;
      background-color: $red;
    }
  }

  &_transparent {
    background-color: transparent;
    color: $dark-primary;
  }

  &_grey {
    background-color: $light-second;
    border: 1px solid $light-second;
    color: $dark-primary;
  }

  &_outlined {
    background-color: transparent;
    border: 1px solid $dark-sixth;
    color: $dark-fourth;

    &:hover {
      background: $gold-main;
      box-shadow: 0 0 20px $gold-main;
    }

    &:active {
      background-color: $gold-main;
    }
  }

  &_outlined-white {
    background: none;
    border: 1px solid $light-primary;
    color: $light-primary;
    padding: 14px 24px;
  }

  &_gold {
    background-color: $gold-main;
    color: $dark-primary;
    transition: all 0.15s linear;

    &:hover {
      box-shadow: 0 0 20px $gold-main;
    }

    &:active {
      box-shadow: none;
    }
  }

  &_gradient {
    background-image: $gold-gradient-2;
    transition: filter 0.2s ease-in-out;
    display: inline-block;
    color: $dark-primary;

    &:after,
    &:before {
      content: "";
      position: absolute;
      border-radius: 8px;
    }

    &:after {
      background-image: linear-gradient(
        269.94deg,
        #d9914e -20.01%,
        #ebbb59 -5.48%,
        #fdfdb7 34.23%,
        #e9b861 102.87%,
        #d78537 133.16%
      );
      z-index: -1;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      display: block;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }

    &:hover {
      filter: drop-shadow(0 0 5px #ffda7a);

      &:after {
        opacity: 1;
      }
    }

    &:active {
      filter: none;
      background-image: linear-gradient(
        271.18deg,
        #d9914e 0.38%,
        #ebbb59 36.1%,
        #ede282 46.7%,
        #e9b861 61.3%,
        #d78537 100%
      );

      &:after {
        opacity: 0;
      }
    }
  }

  &_gradient-loading {
    z-index: 0;
    overflow: hidden;
    color: $dark-primary;

    &::before {
      content: "";
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -30%;
      width: 200%;
      height: 140%;
      background-color: #399953;
      background-repeat: no-repeat;
      background-image: linear-gradient(
        271.18deg,
        #d9914e 0.38%,
        #ebbb59 36.1%,
        #ede282 46.7%,
        #e9b861 61.3%,
        #d78537 100%
      );
      animation: rotate 4s linear infinite;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      left: 1px;
      top: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: white;
      border-radius: 8px;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
</style>
