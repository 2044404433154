<script>
import { mapActions, mapMutations, mapState } from "vuex";
import MenuComponent from "@/components/menu/MenuComponent";
import MainButtonIcon from "@/components/helpers/MainButtonIcon";
import MainNotice from "@/components/helpers/MainNotice";
import MainButton from "@/components/helpers/MainButton";

export default {
  components: {
    MainButton,
    MainButtonIcon,
    MenuComponent,
    MainNotice,
  },

  data() {
    return {
      isShownMenu: false,
      isShownNotice: false,
    };
  },

  computed: {
    ...mapState({
      notification: (state) => state.notification,
    }),

    isShownTitle() {
      return this.$route?.meta?.title;
    },

    isShowMainLayoutButtons() {
      return (
        this.$route.name === "SelfCollectionPartners" ||
        this.$route.name === "SelfCollectionWriteOffs" ||
        this.$route.name === "TransactionsSBPCancelled" ||
        this.$route.name === "TransactionsSBPSuccessful"
      );
    },
  },

  methods: {
    ...mapActions({
      createNotification: "createNotification",
    }),

    ...mapMutations({
      setNotification: "SET_NOTIFICATION",
      setModalState: "SET_MODAL_STATE",
    }),

    hideMenu(classList) {
      if (classList.contains("main-layout__menu-wrapper")) {
        this.isShownMenu = false;
      }
    },

    clearNotification() {
      this.setNotification(null);
    },
  },
};
</script>

<template>
  <div class="main-layout">
    <div
      class="main-layout__menu-wrapper"
      :class="{ 'main-layout__menu-wrapper_visible': isShownMenu }"
      @click="hideMenu($event.target.classList)"
    >
      <MenuComponent class="main-layout__menu" @close="isShownMenu = false" />
    </div>

    <div class="main-layout__header header">
      <img src="../../assets/logo.svg" alt="" />

      <div class="header__company-name">
        <p>АДМИН</p>
      </div>

      <MainButtonIcon class="header__button" @click="isShownMenu = true">
        <span class="header__icon icon-burger-menu"></span>
      </MainButtonIcon>
    </div>

    <div id="main-layout-page-wrapper" class="main-layout__page-wrapper">
      <div
        class="main-layout__title"
        :class="`main-layout__${$route.name}-title`"
      >
        <h1 v-if="isShownTitle">
          {{ $route.meta.title }}
        </h1>

        <div v-if="isShowMainLayoutButtons" class="main-layout__buttons">
          <MainButton
            v-if="false"
            :text="'Загрузить файл списания'"
            :color="'outlined'"
            :size="'medium'"
            :padding="'8px 24px'"
          />

          <MainButton
            v-if="
              $route.name === 'TransactionsSBPSuccessful' ||
              $route.name === 'TransactionsSBPCancelled'
            "
            :text="'Скачать реестр'"
            :color="'gold'"
            :size="'medium'"
            :padding="'8px 24px'"
            @click="
              setModalState({
                modalName: 'isShowDownloadRegistryModal',
                modalState: true,
              })
            "
          />
        </div>
      </div>

      <RouterView />
    </div>

    <MainNotice
      v-if="notification"
      :notice-data="notification"
      :auto-close="true"
      @close="clearNotification"
    />
  </div>
</template>

<style lang="scss" scoped>
.main-layout {
  height: 100%;
  display: grid;
  grid-template-columns: 404px 1fr;

  &__page-wrapper {
    background-color: $light-second;
    padding: 72px 60px 48px;
    overflow: auto;
  }

  &__title {
    margin-bottom: 48px;
    max-width: 876px;

    > h1 {
      @include title-3;
      color: $dark-primary;
    }
  }

  &__SelfCollectionPartners-title,
  &__SelfCollectionWriteOffs-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 475px) {
      flex-direction: column;
      align-items: flex-start;

      > h1 {
        margin-bottom: 16px;
      }
    }
  }

  &__TransactionsSBPSuccessful-title,
  &__TransactionsSBPCancelled-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 475px) {
      flex-direction: column;
      align-items: flex-start;

      > h1 {
        margin-bottom: 16px;
      }
    }
  }

  &__header {
    display: none;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 0 48px;
    background: $light-primary;
  }

  .header {
    &__company-name {
      text-align: left;

      p {
        color: $dark-sixth;
        @include text-2();
        font-weight: 400;
      }
    }

    &__button {
      margin-left: auto;
    }

    &__icon {
      display: block;
      width: 32px;
      height: 32px;
      background-color: $dark-primary;
    }
  }
}

@media (max-width: 1440px) {
  .main-layout {
    &__page-wrapper {
      padding: 72px 48px 48px;
    }
  }
}

@media (max-width: 1280px) {
  .main-layout {
    grid-template-columns: 1fr;
    grid-template-rows: 72px 1fr;

    &__page-wrapper {
      min-width: 100%;
    }

    &__menu-wrapper {
      position: fixed;
      left: -120%;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 99999;
      background: rgba(71, 71, 71, 0.6);

      &_visible {
        left: 0;
      }
    }

    &__menu {
      max-width: 425px;
      width: 100%;
      background-color: $light-primary;
      height: 100%;
    }

    &__header {
      display: flex;
    }
  }
}

@media (max-width: 1024px) {
  .main-layout {
    grid-template-rows: 56px 1fr;

    &__header {
      padding: 0 32px;
    }

    &__page-wrapper {
      padding: 72px 32px 48px;
    }
  }
}

@media (max-width: 500px) {
  .main-layout {
    &__header {
      padding: 12px 16px;
    }

    &__page-wrapper {
      padding: 32px 16px 80px;
    }

    &__title {
      margin-bottom: 32px;
    }
  }
}

@media (max-width: 425px) {
  .main-layout {
    &__header {
      gap: 4px;

      img {
        height: 36px;
      }
    }
  }
}

@media (max-width: 360px) {
  .main-layout {
    &__page-wrapper {
      padding: 32px 16px 80px;
    }

    &__title {
      margin-bottom: 32px;
    }
  }
}
</style>
