<script>
import { mapGetters } from "vuex";
import { MENU_ROUTES } from "@/package/usecases/menu-routes";

export default {
  data() {
    return {
      MENU_ROUTES: MENU_ROUTES,
    };
  },

  computed: {
    ...mapGetters({
      checkPermissions: "checkPermissions",
    }),

    filteredRoutes() {
      return this.MENU_ROUTES.filter((route) => {
        if (route.permissions.length === 0) {
          return route;
        } else {
          return this.checkPermissions(route.permissions);
        }
      });
    },
  },

  methods: {
    setDefaultRoute(currentRoute, routes) {
      if (currentRoute.path === "/" && routes.length > 0) {
        this.$router.push(routes[0].path);
      }
    },
  },

  mounted() {
    this.setDefaultRoute(this.$route, this.filteredRoutes);
  },
};
</script>

<template>
  <div class="menu-routes">
    <router-link
      v-for="route in filteredRoutes"
      :key="route.id"
      :to="route.path"
      class="menu-routes__route"
      active-class="menu-routes__route_active"
    >
      <span :class="`icon-${route.icon}`"></span>
      {{ route.name }}
    </router-link>
  </div>
</template>

<style lang="scss">
.menu-routes {
  display: flex;
  flex-direction: column;

  &__route {
    padding: 16px;
    text-decoration: none;
    @include body-1;
    color: $dark-fifth;
    display: flex;
    align-items: center;

    span {
      display: block;
      width: 24px;
      height: 24px;
      background-color: $dark-fifth;
      margin-right: 8px;
    }

    &_active {
      background-color: $light-third;
      border-radius: 10px;
      @include body-1-bold;
      color: $dark-primary;

      span {
        background-color: $dark-primary;
      }
    }
  }
}
</style>
