export const MENU_ROUTES = [
  {
    id: 1,
    path: "/marketplaces",
    name: "Маркетплейсы",
    icon: "marketplace",
    permissions: ["system", "system.marketplaces", "system.marketplaces.view"],
  },
  {
    id: 2,
    path: "/roles",
    name: "Роли",
    icon: "key",
    permissions: ["system", "system.roles", "system.roles.view"],
  },
  {
    id: 3,
    path: "/staff",
    name: "Сотрудники",
    icon: "people",
    permissions: ["system", "system.access", "system.access.view"],
  },
  {
    id: 4,
    path: "/product-categories",
    name: "Категории товаров",
    icon: "dashboard",
    permissions: ["system", "system.categories", "system.categories.view"],
  },
  {
    id: 5,
    path: "/logs",
    name: "Лента активности",
    icon: "list",
    permissions: ["system", "system.activity", "system.activity.view"],
  },
  {
    id: 6,
    path: "/self-collection",
    name: "Самоинкассация",
    icon: "people",
    permissions: ["system", "system.self_collection"],
  },
  {
    id: 7,
    path: "/moderation",
    name: "Модерация",
    icon: "moderation",
    permissions: ["system", "system.partner_moderation"],
  },
  {
    id: 8,
    path: "/transactions-sbp",
    name: "Транзакции СБП",
    icon: "cashback",
    permissions: ["system.transactions"],
  },
  {
    id: 9,
    path: "/refunds",
    name: "Заявки на возврат",
    icon: "update",
    permissions: ["system.refunds"],
  },
];
