import API from "@/main";
import store from "./index";

const state = {
  marketplaces: [],
  statusMarketplaces: "",
  marketplace: null,

  categories: [],

  partnerMarketplaces: [],
  partnerMarketplace: null,
  partnerCategories: [],
};

const getters = {
  getFileByType: (state) => (type) => {
    return state.partnerMarketplace?.media.filter((file) => {
      return file.type === type;
    });
  },
};

const actions = {
  loadMarketplaces({ commit }, payload) {
    commit("SET_STATUS_MARKETPLACES", "LOADING");

    return new Promise((resolve, reject) => {
      API.get("system/v1/marketplace", {
        params: {
          search: payload.search,
          page: payload.page,
        },
      })
        .then((response) => {
          commit("SET_MARKETPLACES", response.data.data);
          commit("SET_STATUS_MARKETPLACES", "SUCCESS");

          resolve(response);
        })
        .catch((error) => {
          reject(error);

          commit("SET_STATUS_MARKETPLACES", "ERROR");
          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  loadMarketplace({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(`system/v1/marketplace/${payload.marketplaceId}`)
        .then((response) => {
          commit("SET_MARKETPLACE", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  authorizeMarketplace(_, payload) {
    return new Promise((resolve, reject) => {
      API.get(`system/v1/marketplace/${payload.marketplaceId}/authorize`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  storeMarketplace(_, payload) {
    return new Promise((resolve, reject) => {
      API.post("system/v1/marketplace", payload, {
        headers: { "Accept-Language": "ru" },
      })
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Организация добавлена",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          let title = "Что-то пошло не так";
          let description = "Перезагрузите страницу или попробуйте позже";

          if (error.response.status === 422) {
            title = error.response.data.message;
            description = "";
          }

          store.dispatch("createNotification", {
            title: title,
            description: description,
            type: "warning",
          });
        });
    });
  },

  updateMarketplace(_, payload) {
    return new Promise((resolve, reject) => {
      API.put(`system/v1/marketplace/${payload.id}`, payload, {
        headers: { "Accept-Language": "ru" },
      })
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Данные изменены",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          let title = "Что-то пошло не так";
          let description = "Перезагрузите страницу или попробуйте позже";

          if (error.response.status === 422) {
            title = error.response.data.message;
            description = "";
          }

          store.dispatch("createNotification", {
            title: title,
            description: description,
            type: "warning",
          });
        });
    });
  },

  deleteMarketplace(_, payload) {
    return new Promise((resolve, reject) => {
      API.delete(
        `system/v1/marketplace/${payload.marketplaceId}/soft-delete`,
        payload
      )
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Организация удалена",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  loadCategories({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("system/v1/marketplace/category/all")
        .then((response) => {
          commit("SET_CATEGORIES", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  loadPartnerMarketplaces({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(`system/v1/marketplace/${payload.marketplaceId}/partner`, {
        params: {
          search: payload.search,
          page: payload.page,
        },
      })
        .then((response) => {
          commit("SET_PARTNER_MARKETPLACES", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  loadPartnerMarketplace({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(
        `system/v1/marketplace/${payload.marketplaceId}/partner/${payload.partnerId}`
      )
        .then((response) => {
          commit("SET_PARTNER_MARKETPLACE", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  storePartnerMarketplace(_, payload) {
    return new Promise((resolve, reject) => {
      API.post(
        `system/v1/marketplace/${payload.marketplaceId}/partner`,
        payload,
        {
          headers: { "Accept-Language": "ru" },
        }
      )
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Организация добавлена",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          let title = "Что-то пошло не так";
          let description = "Перезагрузите страницу или попробуйте позже";

          if (error.response.status === 422) {
            title = error.response.data.message;
            description = "";
          }

          store.dispatch("createNotification", {
            title: title,
            description: description,
            type: "warning",
          });
        });
    });
  },

  updatePartnerMarketplace({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.put(
        `system/v1/marketplace/${payload.marketplace_id}/partner/${payload.id}`,
        payload,
        {
          headers: { "Accept-Language": "ru" },
        }
      )
        .then((response) => {
          commit("SET_PARTNER_MARKETPLACE", response.data);

          store.dispatch("createNotification", {
            title: "Данные изменены",
            type: "success",
          });

          resolve(response);
        })
        .catch((error) => {
          reject(error);

          let title = "Что-то пошло не так";
          let description = "Перезагрузите страницу или попробуйте позже";

          if (error.response.status === 422) {
            title = error.response.data.message;
            description = "";
          }

          store.dispatch("createNotification", {
            title: title,
            description: description,
            type: "warning",
          });
        });
    });
  },

  deletePartnerMarketplace(_, payload) {
    return new Promise((resolve, reject) => {
      API.delete(
        `system/v1/marketplace/${payload.marketplaceId}/partner/${payload.partnerId}/soft-delete`,
        payload
      )
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Организация удалена",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  loadPartnerCategories({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(`system/v1/marketplace/${payload.marketplaceId}/category`)
        .then((response) => {
          commit("SET_PARTNER_CATEGORIES", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  authorizePartnerMarketplace(_, payload) {
    return new Promise((resolve, reject) => {
      API.get(
        `system/v1/marketplace/${payload.marketplaceId}/partner/${payload.partnerId}/authorize`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },
};

const mutations = {
  SET_MARKETPLACES(state, payload) {
    state.marketplaces = payload;
  },

  SET_STATUS_MARKETPLACES(state, payload) {
    state.statusMarketplaces = payload;
  },

  SET_MARKETPLACE(state, payload) {
    state.marketplace = payload;
  },

  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },

  SET_PARTNER_MARKETPLACES(state, payload) {
    state.partnerMarketplaces = payload;
  },

  SET_PARTNER_MARKETPLACE(state, payload) {
    state.partnerMarketplace = payload;
  },

  SET_PARTNER_CATEGORIES(state, payload) {
    state.partnerCategories = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
