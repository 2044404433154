import API from "@/main.js";

const state = {
  user: null,
  currentAccess: null,

  permissions: [],
};

const getters = {};

const actions = {
  auth() {
    return new Promise((resolve, reject) => {
      API.get("/sanctum/csrf-cookie")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loginUser(_, data) {
    return new Promise((resolve, reject) => {
      API.post("/auth/login", {
        username: data.username,
        password: data.password,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  checkUser({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("system/v1/profile/check")
        .then((response) => {
          commit("SET_USER", response.data.data);
          commit("SET_CURRENT_ACCESS", response.data.current_access);
          commit("SET_PERMISSIONS", response.data.current_access.permissions);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  logout() {
    return new Promise((resolve, reject) => {
      API.get("auth/logout")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  resetPassword(_, user) {
    return new Promise((resolve, reject) => {
      API.post(`auth/${user}/password/reset`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_USER(state, payload) {
    state.user = payload;
  },

  SET_CURRENT_ACCESS(state, payload) {
    state.currentAccess = payload;
  },

  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
