import API from "@/main";
import store from "./index";

const state = {
  roles: [],
  role: null,

  contextPermissions: [],
  contextRoles: [],
};

const getters = {};

const actions = {
  loadRolesPermissions({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get("auth/context/role-permissions/list", {
        params: {
          context: payload.context,
        },
      })
        .then((response) => {
          commit("SET_CONTEXT_PERMISSIONS", response.data.data.permissions);
          commit("SET_CONTEXT_ROLES", response.data.data.roles);

          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  loadRoles({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get("system/v1/roles", {
        params: {
          context: payload.context,
          page: payload.page,
        },
      })
        .then((response) => {
          commit("SET_ROLES", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  loadRole({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(`system/v1/roles/${payload.roleId}`)
        .then((response) => {
          commit("SET_ROLE", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  storeRole(_, payload) {
    return new Promise((resolve, reject) => {
      API.post(
        "system/v1/roles",
        {
          context: payload.context,
          name: payload.name,
          permissions: payload.permissions,
        },
        {
          headers: { "Accept-Language": "ru" },
        }
      )
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Роль добавлена",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          let title = "Что-то пошло не так";
          let description = "Перезагрузите страницу или попробуйте позже";

          if (error.response.status === 422) {
            title = error.response.data.message;
            description = "";
          }

          store.dispatch("createNotification", {
            title: title,
            description: description,
            type: "warning",
          });
        });
    });
  },

  updateRole(_, payload) {
    return new Promise((resolve, reject) => {
      API.put(
        `system/v1/roles/${payload.roleId}`,
        {
          context: payload.context,
          name: payload.name,
          permissions: payload.permissions,
        },
        {
          headers: { "Accept-Language": "ru" },
        }
      )
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Данные изменены",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          let title = "Что-то пошло не так";
          let description = "Перезагрузите страницу или попробуйте позже";

          if (error.response.status === 422) {
            title = error.response.data.message;
            description = "";
          }

          store.dispatch("createNotification", {
            title: title,
            description: description,
            type: "warning",
          });
        });
    });
  },

  deleteRole(_, payload) {
    return new Promise((resolve, reject) => {
      API.delete(`system/v1/roles/${payload.roleId}`)
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Роль удалена",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);
          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },
};

const mutations = {
  SET_ROLES(state, payload) {
    state.roles = payload;
  },

  SET_ROLE(state, payload) {
    state.role = payload;
  },

  SET_CONTEXT_PERMISSIONS(state, payload) {
    state.contextPermissions = payload;
  },

  SET_CONTEXT_ROLES(state, payload) {
    state.contextRoles = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
