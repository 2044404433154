import API from "@/main";
import store from "./index";

const state = {
  persons: [],
  statusPersons: "",
  person: null,
};

const getters = {};

const actions = {
  loadPersons({ commit }, payload) {
    commit("SET_STATUS_PERSONS", "LOADING");

    return new Promise((resolve, reject) => {
      API.get("system/v1/access", {
        params: {
          search: payload.search,
          page: payload.page,
        },
      })
        .then((response) => {
          commit("SET_PERSONS", response.data.data);
          commit("SET_STATUS_PERSONS", "SUCCESS");

          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_STATUS_PERSONS", "ERROR");

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  loadPerson({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(`system/v1/access/${payload.personId}`)
        .then((response) => {
          commit("SET_PERSON", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  storePerson(_, payload) {
    return new Promise((resolve, reject) => {
      API.post(
        "system/v1/access",
        {
          first_name: payload.firstName,
          last_name: payload.lastName,
          middle_name: payload.middleName,
          email: payload.email,
          roles: [payload.role.name],
          password: payload.abrakadabra,
        },
        {
          headers: { "Accept-Language": "ru" },
        }
      )
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Сотрудник добавлен",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          let title = "Что-то пошло не так";
          let description = "Перезагрузите страницу или попробуйте позже";

          if (error.response.status === 422) {
            title = error.response.data.message;
            description = "";
          }

          store.dispatch("createNotification", {
            title: title,
            description: description,
            type: "warning",
          });
        });
    });
  },

  updatePerson(_, payload) {
    return new Promise((resolve, reject) => {
      API.put(`system/v1/access/${payload.id}`, payload.data, {
        headers: { "Accept-Language": "ru" },
      })
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Данные изменены",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          let title = "Что-то пошло не так";
          let description = "Перезагрузите страницу или попробуйте позже";

          if (error.response.status === 422) {
            title = error.response.data.message;
            description = "";
          }

          store.dispatch("createNotification", {
            title: title,
            description: description,
            type: "warning",
          });
        });
    });
  },

  deletePerson(_, payload) {
    return new Promise((resolve, reject) => {
      API.delete(`system/v1/access/${payload.personId}`)
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Сотрудник удален",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },
};

const mutations = {
  SET_PERSONS(state, payload) {
    state.persons = payload;
  },

  SET_STATUS_PERSONS(state, payload) {
    state.statusPersons = payload;
  },

  SET_PERSON(state, payload) {
    state.person = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
