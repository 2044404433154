import API from "@/main";
import store from "@/store/index";

const state = {
  partners: [],
  statusPartners: "",
  partner: null,

  paymentMethods: null,
  partnerLimits: null,
};

const getters = {
  getFileByType: (state) => (type) => {
    return state.partner?.media.filter((file) => {
      return file.type === type;
    });
  },
};

const actions = {
  loadPartners({ commit }, data) {
    commit("SET_STATUS_PARTNERS", "LOADING");

    return new Promise((resolve, reject) => {
      API.get("system/v1/partner-moderation", {
        params: data.params,
      })
        .then((response) => {
          commit("SET_PARTNERS", response.data.data);
          commit("SET_STATUS_PARTNERS", "SUCCESS");

          resolve(response);
        })
        .catch((error) => {
          commit("SET_STATUS_PARTNERS", "ERROR");

          reject(error);
        });
    });
  },

  loadPartner({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`system/v1/partner-moderation/${data.partnerId}`)
        .then((response) => {
          commit("SET_PARTNER", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updatePartner(_, data) {
    return new Promise((resolve, reject) => {
      API.put(`system/v1/partner-moderation/${data.id}`, data)
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Данные изменены",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          let title = "Что-то пошло не так";
          let description = "Перезагрузите страницу или попробуйте позже";

          if (error.response.status === 422) {
            title = error.response.data.message;
            description = "";
          }

          store.dispatch("createNotification", {
            title: title,
            description: description,
            type: "warning",
          });
        });
    });
  },

  activatePartner(_, data) {
    return new Promise((resolve, reject) => {
      API.put(`system/v1/partner-moderation/${data.partnerId}/activate`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deletePartner(_, data) {
    return new Promise((resolve, reject) => {
      API.delete(`system/v1/partner-moderation/${data.partnerId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadPaymentMethods({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`system/v1/partner-moderation/${data.partnerId}/payment-data`)
        .then((response) => {
          commit("SET_PAYMENT_METHODS", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  storePaymentData(_, data) {
    return new Promise((resolve, reject) => {
      API.post(
        `system/v1/partner-moderation/${data.partnerId}/payment-data`,
        data.data
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  destroyPaymentData(_, data) {
    return new Promise((resolve, reject) => {
      API.delete(
        `system/v1/partner-moderation/${data.partnerId}/payment-data/${data.providerId}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changeCashierStatus(_, data) {
    return new Promise((resolve, reject) => {
      API.put(
        `system/v1/partner-moderation/${data.partnerId}/cashier/toggle`,
        data.data
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadPartnerLimits({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`system/v1/partner-moderation/${data.partnerId}/limit`)
        .then((response) => {
          commit("SET_PARTNER_LIMITS", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  storePartnerLimits(_, data) {
    return new Promise((resolve, reject) => {
      API.post(
        `system/v1/partner-moderation/${data.partnerId}/limit`,
        data.params
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deletePartnerLimits(_, data) {
    return new Promise((resolve, reject) => {
      API.delete(`system/v1/partner-moderation/${data.partnerId}/limit`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_PARTNERS(state, payload) {
    state.partners = payload;
  },

  SET_STATUS_PARTNERS(state, payload) {
    state.statusPartners = payload;
  },

  SET_PARTNER(state, payload) {
    state.partner = payload;
  },

  SET_PAYMENT_METHODS(state, payload) {
    state.paymentMethods = payload;
  },

  SET_PARTNER_LIMITS(state, payload) {
    state.partnerLimits = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
